import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polis`}</h1>
    <p>{`In plain language, Polis is a an `}<a parentName="p" {...{
        "href": "/open-source",
        "title": "open source"
      }}>{`open source`}</a>{` technology for survey research that leverages data science. It is described on the website as:`}</p>
    <blockquote>
      <p parentName="blockquote">{`a real-time system for gathering, analyzing and understanding what large groups of people think in their own words, enabled by advanced statistics and machine learning.`}</p>
    </blockquote>
    <p>{`More specifically, Polis is a platform for a `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{`, in which `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` submit short text statements, or `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{`, (<140 characters) which are then sent out `}<a parentName="p" {...{
        "href": "/semi-randomly",
        "title": "semi-randomly"
      }}>{`semi-randomly`}</a>{` to other participants to `}<a parentName="p" {...{
        "href": "/vote",
        "title": "vote"
      }}>{`vote`}</a>{` on by clicking agree, disagree or `}<a parentName="p" {...{
        "href": "/pass",
        "title": "pass"
      }}>{`pass`}</a>{`. Polis allows `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` `}<a parentName="p" {...{
        "href": "/owners",
        "title": "owners"
      }}>{`owners`}</a>{` to create `}<a parentName="p" {...{
        "href": "/conversations",
        "title": "conversations"
      }}>{`conversations`}</a>{` which can seamlessly engage (currently) up to hundreds of thousands or (conceivably) `}<a parentName="p" {...{
        "href": "/millions-of-participants",
        "title": "millions of participants"
      }}>{`millions of participants`}</a>{`.`}</p>
    <p>{`Polis provides automatic (real-time, web-based `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{`) and CompDem, or your own data scientists, can produce manual (python Jupyter `}<a parentName="p" {...{
        "href": "/notebooks",
        "title": "notebooks"
      }}>{`notebooks`}</a>{` based) data science analysis of the resulting `}<a parentName="p" {...{
        "href": "/polis-opinion-matrix",
        "title": "polis opinion matrix"
      }}>{`polis opinion matrix`}</a>{` available in the data `}<a parentName="p" {...{
        "href": "/export",
        "title": "export"
      }}>{`export`}</a>{`. See: `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{`.`}</p>
    <p>{`Polis is a `}<a parentName="p" {...{
        "href": "/Wikisurvey",
        "title": "💡 Wikisurvey"
      }}>{`💡 Wikisurvey`}</a>{`, as:`}</p>
    <ul>
      <li parentName="ul">{`the `}<a parentName="li" {...{
          "href": "/dimensions",
          "title": "dimensions"
        }}>{`dimensions`}</a>{` of the survey are created by the `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` themselves`}</li>
      <li parentName="ul">{`the survey adapts to participation over time and makes good use of people's time by showing comments semi-randomly`}</li>
      <li parentName="ul">{`participants do not need to complete the entire survey to contribute meaning`}</li>
    </ul>
    <p>{``}<a parentName="p" {...{
        "href": "/pol.is",
        "title": "pol.is"
      }}>{`pol.is`}</a>{` is the main instance of the technology `}<a parentName="p" {...{
        "href": "/hosted",
        "title": "hosted"
      }}>{`hosted`}</a>{` online, but there are other instances in the wild.`}</p>
    <p>{`In its highest ambition, `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is a platform for enabling `}<a parentName="p" {...{
        "href": "/collective-intelligence",
        "title": "collective intelligence"
      }}>{`collective intelligence`}</a>{` in human societies and fostering mutual understanding at `}<a parentName="p" {...{
        "href": "/scale",
        "title": "scale"
      }}>{`scale`}</a>{` in the tradition of `}<a parentName="p" {...{
        "href": "https://www.cnvc.org/learn-nvc/what-is-nvc"
      }}>{`nonviolent communication`}</a>{`. `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is `}<a parentName="p" {...{
        "href": "/open-source",
        "title": "open source"
      }}>{`open source`}</a>{` software released under the `}<a parentName="p" {...{
        "href": "/AGPL3",
        "title": "AGPL3"
      }}>{`AGPL3`}</a>{` license`}</p>
    <p>{`We generally pronounce it like `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=hBVyr9RxE7o"
      }}>{`this`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/tomatlee"
      }}>{`Tom Atlee`}</a>{` offers a more holistic narrative description of `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Polis might at first look like a fancy online opinion polling tool.  But it can more accurately be described as an evolving, lively, artificially intelligent, interactive “suggestion box” that can realize consensus around a controversial question. It works like this:  Dozens or thousands of participants submit short statements expressing their views on the question.  They also respond to other participants’ statements with Agree, Disagree, or Pass.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`As hundreds of these statements and evaluations accumulate, a behind-the-scenes algorithm sorts participants into diverse clusters of like-minded responders.  The algorithm then identifies “consensus statements" about which all the diverse clusters agree. At any time, participants can view graphics revealing the whole ecosystem of perspectives as it evolves. If they wish, they can create new statements that respond to what they see in that overview.  Over time their statements tend to become more specific and practical.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`After days or weeks of this activity, the results - both consensus statements and divisive statements - are usually turned over to a dialogue among diverse stakeholders, citizens or decision-makers to inform and stimulate their creative responses to the question. Activities can be organized through which views from polis exercises and dialogues feed back and forth into each other to generate deepening insight and/or specificity.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Polis has been used with populations ranging from 40 to 40,000 people.  It is ideal for discovering unrealized possibilities in complex, conflicted situations involving widely diverse perspectives.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      